

:host {
  display: grid;
  position: relative;
  font-family: inherit;
  --layout-grid-gap: 1rem;
  --layout-padding: 4rem;
  --layout-width: calc(100% - (var(--layout-grid-gap) * 2));
  --layout: 1fr;
  --tiny: 420px;
  --small: 700px;
  --medium: 1024px;
  --large: 1200px;
}

:host .layout {
  display: grid;
  grid-gap: var(--layout-grid-gap, 3rem);
  margin: 0 auto;
  padding: var(--layout-padding) 0;
  width: var(--layout-width);
  max-width: var(--medium);
  min-height: 0;
  min-width: 0;
  grid-template-columns: var(--layout);
}

:host([small]) .layout {
  grid-template-columns: var(--layout-small, 1fr);
}

:host .layout > * {
  min-width: 0;
}

:host([size="tiny"]) .layout {
  max-width: var(--tiny);
}

:host([size="small"]) .layout {
  max-width: var(--small);
}

:host([size="large"]) .layout {
  max-width: var(--large);
}

:host([size="full"]) .layout {
  max-width: 100%;
}

:host([size="flush"]) .layout {
  max-width: 100%;
  width: 100%;
}

:host([padding="none"]) {
  --layout-padding: 0;
  --layout-grid-gap: 0;
}

:host([padding="tiny"]) {
  --layout-padding: 0.5rem;
  --layout-grid-gap: 0.25rem;
}

:host([padding="small"]) {
  --layout-padding: 1rem;
  --layout-grid-gap: 0.5rem;
}

:host([padding="large"]) {
  --layout-padding: 3rem;
  --layout-grid-gap: 6rem;
}

:host([align="top"]) .layout {
  align-items: flex-start;
}

:host([align="center"]) .layout {
  align-items: center;
}

:host([align="baseline"]) .layout {
  align-items: baseline;
}

:host([type="supporting-content-right"]) ::slotted(aside) {
  order: 1;
}

:host ::slotted(*) {
  min-width: 0;
}

:host([has-nav]) .layout {
  padding-bottom: 0;
}

:host(:not([type="default"])) ::slotted(*[slot="nav"]) {
  grid-column: span 2;
}

:host ::slotted(*[slot="nav"]) {
  margin-top: calc(var(--layout-padding) / 2);
}

/* Responsive sizes */
:host(:not([small])) {
  --layout-width: calc(100% - (var(--layout-grid-gap) * 4));
}

:host([type="one-third"]:not([small])) {
  --layout: 2fr 1fr;
}

:host([type="one-third-right"]:not([small])) {
  --layout: 1fr 2fr;
}

:host([type="sidebar-right"]:not([small])) {
  --layout: 3fr 1fr;
}

:host([type="sidebar"]:not([small])) {
  --layout: 1fr 3fr;
}

:host([type="locked-sidebar"]:not([small])) {
  --layout: 30rem 3fr;
}

:host([type="locked-sidebar-right"]:not([small])) {
  --layout: 30rem 3fr;
}

:host([type="half"]:not([small])) {
  --layout: 1fr 1fr;
}

:host([type="supporting-content"]:not([small])) {
  --layout: 6fr 4fr;
}

:host([type="supporting-content-right"]:not([small])) {
  --layout: 4fr 6fr;
}

:host([type="split-screen"]:not([small])) {
  --layout: 1fr 1fr;
}

:host([type*="-right"]:not([small])) ::slotted(aside) {
  order: -1;
}
